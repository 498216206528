<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/86.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  4-1/2" SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-180C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/87.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  4-1/2" SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-181C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/88.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  4-1/2" SIDE CUTTER PLIERS



                </h4>
                <h5 style="text-align: center">SINT-182C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/89.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 5-1/2" WIRE STRIPPER PLIERS



                </h4>
                <h5 style="text-align: center">SINT-184C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/90.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 5" SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-250C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/91.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                6" LONG NOSE PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-251C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/92.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                6" FLAT NOSE PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-252C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/93.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                6" BENT NOSE PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-253C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/94.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 5-1/2" ROUND NOSE PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-254C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/95.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                5" SIDE CUTTER PLIERS – SLIM
 


                </h4>
                <h5 style="text-align: center">SINT-256C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/96.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                5" SIDE CUTTER PLIERS – SLIM
 


                </h4>
                <h5 style="text-align: center">SINT-257C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/97.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                5" SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-258C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/98.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 5-1/2" WIRE STRIPPER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-259C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/99.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                6" WIRE STRIPPER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-260C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/100.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                6" WIRE STRIPPER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-261C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/101.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                5" HEAVY DUTY SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-280C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/102.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 5" HEAVY DUTY SIDE CUTTER PLIERS
 


                </h4>
                <h5 style="text-align: center">SINT-280AC</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/103.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                5" HEAVY DUTY SIDE CUTTER PLIERS – SLIM
 


                </h4>
                <h5 style="text-align: center">SINT-281C</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/104.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                TELESCOPING INSPECTION MIRROR
  


                </h4>
                <h5 style="text-align: center">SINT-230</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/105.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                TELESCOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-231</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/106.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 TELECOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-232</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/107.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                TELESCOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-233</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/108.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                TELESCOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-234</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/109.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                TELESCOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-235</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/110.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 TELESCOPING INSPECTION MIRROR
 


                </h4>
                <h5 style="text-align: center">SINT-236</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/111.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                STATIC DISSIPATIVE TWEEZER
 


                </h4>
                <h5 style="text-align: center">SINT-295</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/112.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                STATIC DISSIPATIVE TWEEZER
 


                </h4>
                <h5 style="text-align: center">SINT-296</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/113.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                STATIC DISSIPATIVE TWEEZER
 


                </h4>
                <h5 style="text-align: center">SINT-297</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/114.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 ESD PROBE
 


                </h4>
                <h5 style="text-align: center">SINT-298</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/115.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
            STATIC DISSIPATIVE TWEEZERS


                </h4>
                <h5 style="text-align: center">SINT-299</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/116.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                ESD DESOLDERING PUMPS
 


                </h4>
                <h5 style="text-align: center">SINT-1003</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/117.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                ESD DESOLDERING PUMP
 


                </h4>
                <h5 style="text-align: center">SINT-1006</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->

      
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/118.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                 SOLDERING WICK
 


                </h4>
                <h5 style="text-align: center">SINT-311</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/esdtools/handtools">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/suitin/119.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                MINI VISE
 


                </h4>
                <h5 style="text-align: center">SINT-VS-90</h5>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        

      
      </div>
     
    
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>